.appBar {
    background-color: #f8f9fa !important;
    padding: 8px 0px !important;
    box-shadow: 0 4px 2px -2px grey !important;
    position: static !important;
}

.nav-link {
    text-decoration: none;
    position: relative;
    transition: color 0.3s;
}

.active-link::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0e2855;
    transition: width 0.3s;
}

.nav-link:hover::after {
    width: 100%;
}

.navItens {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0
}