.table {

    .MuiPaper-root {
        border-radius: 0;
        overflow: hidden;
    }

    .MuiTableCell-root {
        font-family: sans-serif;
        font-size: 1rem;
        color: black;
    }

    .MuiTableHead-root .MuiTableCell-root {
        background-color: #cfe2ff;
        color: black;
        font-weight: bold;
        border: 1px solid #ccc;
        text-align: center;
        padding: 9px;

    }

    .MuiTableRow-root:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .MuiTableCell-root {
        border: 1px solid #dee2e6;
        padding: 8px;
    }

    .MuiTableFooter-root .MuiTableCell-root {
        background-color: #cfe2ff;
        color: black;
        font-weight: bold;
    }
}