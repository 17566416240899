.autoCompleteOrcamento {
    width: 100%;
    margin-bottom: 2px;

    .MuiOutlinedInput-root {
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        height: 45px;
        font-size: 15px;
        padding-top: 30px !important;
        padding-bottom: 8px;
        color: black;

    }

    .MuiOutlinedInput-root:hover {
        border-color: #dee2e6;
    }

    .MuiOutlinedInput-root.Mui-focused {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dee2e6;
    }

    .MuiOutlinedInput-notchedOutline:hover {
        border-color: inherit;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dcdcdc;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #dee2e6;
    }

    .MuiOutlinedInput-root.Mui-disabled {
        background-color: #e9ecef;

        .MuiOutlinedInput-notchedOutline {
            border-color: #dee2e6;
        }

    }

    .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
        -webkit-text-fill-color: black;
    }

    .MuiAutocomplete-input {
        transform: translateY(-25%);
        font-size: 1rem;
    }
}