.inputContainer {
    width: 100%;
    position: relative;
}

.inputWrapper {
    position: relative;
    width: 100%;
}

.inputNumerico {
    width: 100%;
    height: 58px;
    padding: 20px 10px 0px;
    font-size: 15px;
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
    color: black;
    background-color: white;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        outline: none;
        border-color: #0d6efd;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:disabled {
        background-color: #e9ecef;
        color: black;
    }


}

.floatingLabel {
    position: absolute;
    top: 8px;
    left: 10px;
    font-size: 13px;
    color: rgb(13, 110, 253);
    font-family: sans-serif;
    pointer-events: none;
}

.filled {
    top: 8px;
    font-size: 12px;
    color: #495057;
}

.inputForm:focus+.floatingLabel,
.inputForm:not(:placeholder-shown)+.floatingLabel {
    top: 8px;
    font-size: 12px;
    color: #495057;
}