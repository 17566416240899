.autoComplete {
    width: 100%;
    margin-bottom: 10px;

    .MuiOutlinedInput-root {
        border-radius: var(--bs-border-radius);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        height: 58px;
        font-size: 15px;
        padding-top: 30px !important;
        padding-bottom: 8px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    }

    .MuiOutlinedInput-root.Mui-focused {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dee2e6;
    }

    .MuiOutlinedInput-notchedOutline:hover {
        border-color: inherit;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dcdcdc;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #dee2e6;
    }

    .MuiInputLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.6)
    }

    .MuiInputLabel-root {
        position: absolute;
        top: 18px;
        transform: translateY(-50%);
        left: 12px;
        pointer-events: none;
        font-size: 13px
    }

}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    align-items: center;
    align-content: space-between;
}

.total-registros {
    grid-column: span 1;
}

.button-group {
    grid-column: 4 / 5;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}