.dados-linha {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.fornecedor {
    flex: 2;
}

.valor {
    flex: 1;
}