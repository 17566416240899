.tabelaPaginada {
    .MuiDataGrid-root {
        border-radius: 0;
    }

    .MuiDataGrid-row {
        color: black
    }

    .MuiDataGrid-columnHeader {
        color: black;
        font-size: 13px;
        border-right: 1px solid #303030;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: 700;
        white-space: normal;
        word-break: break-word;
        overflow-wrap: anywhere;
        text-align: center;
        width: 100%;
    }

    .MuiIconButton-root {
        font-size: 12px;
    }

    .MuiDataGrid-cell {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        font-size: 13px !important;
        border-right: 1px solid #dee2e6;
        padding: 10px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        line-height: unset !important;
        white-space: normal !important;
    }

    .MuiDataGrid-row:nth-of-type(odd) {
        background-color: #0000000d;
    }

    .MuiDataGrid-row:nth-of-type(even) {
        background-color: #ffffff;
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-columnHeader:focus {
        outline: none !important;
    }

    .MuiDataGrid-row.Mui-selected {
        background-color: #ffffff !important;
    }

    .MuiDataGrid-overlayWrapper {
        height: 100px;
    }
}