.tableOrcamento {

    .linha-verde .MuiInputBase-input {
        color: rgb(3, 177, 3) !important;
    }

    .linha-vermelha {
        color: red !important;
    }

    .linha-verde {
        color: rgb(3, 177, 3) !important;
    }

    .linha-vermelha .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: red !important;
        color: red !important;
    }

    .linha-verde .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: rgb(3, 177, 3) !important;
        color: rgb(3, 177, 3) !important;
    }

    .MuiPaper-root {
        border-radius: 0;
        overflow: hidden;
    }

    .MuiTableCell-root {
        padding: .5rem .5rem;
        font-size: 1rem;
        color: black;
        border: 1px solid #dee2e6;

    }

    .MuiTableHead-root .MuiTableCell-root {
        background-color: #cfe2ff;
        color: black;
        font-size: 15px;
        border: 1px solid #ccc;
        font-weight: bold;
        text-align: center;
    }

    .MuiTableRow-root:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.inputTabela {
    width: 100%;
    margin-bottom: 2px;

    .MuiOutlinedInput-root {
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        height: 36px;
        font-size: 15px;
        color: black;
        background-color: white;
        font-family: sans-serif
    }

    .MuiOutlinedInput-root:hover {
        border-color: #dee2e6;
    }

    .MuiOutlinedInput-root.Mui-focused {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dee2e6;
    }

    .MuiOutlinedInput-notchedOutline:hover {
        border-color: inherit;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dcdcdc;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #dee2e6;
    }

    .MuiOutlinedInput-root.Mui-disabled {
        background-color: #e9ecef;

        .MuiOutlinedInput-notchedOutline {
            border-color: #dee2e6;
        }

    }

    .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
        -webkit-text-fill-color: black;
    }

}

.inputNumericoTabela {
    width: 100%;
    height: 36px;
    padding: 10px;
    font-size: 15px;
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
    color: black;
    background-color: white;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        outline: none;
        border-color: #0d6efd;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:disabled {
        background-color: #e9ecef;
        color: black;
    }
}